<template>
  <v-card flat class="main-card px-6 py-3">
    <h1>Kunjungan Rawat Jalan Hari ini</h1>
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="height: 90%;"
    >
      <v-progress-circular
        color="primary lighten-2"
        size="100"
        width="6"
        indeterminate
      />
    </div>
    <div class="d-flex justify-center" v-if="!loading">
      <apexchart
        type="donut"
        width="280"
        :options="chartOptions"
        :series="series"
      />
    </div>
    <div
      style="height: 11vw;"
      class="d-flex flex-column justify-end"
      v-if="!loading"
    >
      <div
        v-for="(item, index) in chartOptions.labels"
        :key="`text-${index}`"
        class="d-flex justify-space-between"
      >
        <div class="d-flex align-center">
          <v-icon :color="chartOptions.colors[index]" small class="mr-3"
            >mdi-circle</v-icon
          >
          <span class="font-weight-regular">{{ item }}</span>
        </div>
        <span class="font-weight-bold title">{{ series[index] }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PieChartDashboard',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      series: [10, 20],
      chartOptions: {
        labels: ['Poli 1', 'Poli 2'],
        colors: ['#31b057', '#59abe1', '#fbaf18', '#7c59e1', '#e1d359'],
        chart: {
          width: 300,
          type: 'donut',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  color: '#757575',
                  fontSize: '20px',
                  fontWeight: '600',
                },
                value: {
                  show: true,
                  showAlways: true,
                  fontSize: '35px',
                  fontWeight: '600',
                },
              },
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
          show: false,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  border: 2.5px solid #e0e0e0;
  border-radius: 8px;
  height: 100%;

  span {
    font-size: 0.9vw;
  }

  h1 {
    font-size: 1vw;
    text-align: start;
    margin-bottom: 1vw;
  }
}
</style>
